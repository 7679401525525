.l-footer {
    margin-top: clamp(7.3206vw,7.3206vw,100px);
    display: flex;
    font-size: clamp(1.31771vw,1.31771vw,18px);
    @include max {
        margin-top: 12.0772vw;
        display: block;
        font-size: 4.3478vw;
    }
    .tumblr {
        width: clamp(32.9428vw,32.9428vw,450px);
        @include max {
            width: 100%;
            margin-bottom: 12.0772vw;
        }
    }
    .address {
        width: clamp(32.9428vw,32.9428vw,450px);
        @include max {
            width: 100%;
            margin-bottom: 12.0772vw;
        }
        a {
            color: #999999;
            font-size: clamp(0.8784vw,0.8784vw,12px);
            @include max {
                margin-bottom: 12.0772vw;
                font-size: 2.8985vw;
            }
        }
    }
    .member {
        span {
            color: #999999;
        }
    }
}



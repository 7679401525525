.p-ft {
  &__nav {
    padding: 0 15px;
    ul {
      text-align: center;
    }
    li {
      display: inline-block;
      margin: 0 0.8em .5em;
      a {
        @include min {
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  &__copy {
    padding: 0 15px;
    text-align: center;
  }
}
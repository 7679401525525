*, *:before, *:after {
  box-sizing: border-box;
}
main {
  display: block;
}

html {
  font-size: 10px;
}
body {
  line-height: 1.5;
  font-family: $f-sans;
  font-size: 1.3rem;
  color: #000;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  .remodal-overlay {
    background-color: rgba(#000, .9);
  }
  .remodal-wrapper {
    padding: 120px 30px 30px;
    @include max {
      padding: 0;
      height: 100%;
//      overflow: hidden;
      z-index: 99999;
    }
  }
  .remodal {
    max-width: 1125px;
    padding: 0;
    vertical-align: top;
    background: none;
    @include max {
      margin-bottom: 0;
      padding: 70px 20px 20px;
    }
  }
  .remodalImg {
    padding: 0;
    background: none;
  }
  .remodalMovie {
    padding: 0;
    background: none;
    @include max {
      width: 100%;
      height: 100%;
    }
  }
  .remodal-close {
    position: absolute;
    left: auto;
    right: -100px;
    top: -80px;
    width: 60px;
    height: 60px;
    @include max(xl) {
      right: -18px;
    }
    @include max {
      right: 5px;
      top: 5px;
      width: 45px;
      height: 45px;
      z-index: 1;
    }
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      width: 35px;
      height: 3px;
      background-color: #fff;
      transform: translate(-50%, -50%) rotate(45deg);
      @include max {
        width: 20px;
        height: 2px;
      }
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      width: 35px;
      height: 3px;
      background-color: #fff;
      transform: translate(-50%, -50%) rotate(-45deg);
      @include max {
        width: 20px;
        height: 2px;
      }
    }
  }
  .remodalMovie .remodal-close {
    @include max {
      &:before {
        background-color: #fff;
      }
      &:after {
        background-color: #fff;
      }
    }
  }
  .remodalBtn {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    @include max {
      margin-top: 30px;
    }
    a {
      width: 450px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0.1em;
      border-radius: 50px;
      background-color: #fff;
      @include max {
        width: 300px;
        height: 40px;
        font-size: 14px;
      }
    }
  }
}
a {
  color: #000;
  text-decoration: none;
  @include max {
    &:hover {
      color: #000;
    }
  }
  &:hover {
      text-decoration: underline;
  }
}
img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}
h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}
ul, ol {
  list-style-type: none;
}
th, strong, b {
  font-weight: normal;
}
em, i {
  font-style: normal;
}

.fade {
  opacity: 0;
  transform: translateY(50px);
  transition: all 1s;
  &-next {
    opacity: 0;
    transform: translateY(50px);
    transition: all 1s;
  }
  &.is-fade {
    opacity: 1;
    transform: translateY(0);
  }
  &-next.is-fade-next {
    opacity: 1;
    transform: translateY(0);
  }
}

.p-hd {
  &__cols {
    display: flex;
    justify-content: space-between;
  }
  &__col {
    &:nth-child(1) {
      display: flex;
    }
    &:nth-child(2) {
      display: flex;
      align-items: center;
      padding-right: 48px;
      height: 77px;
      @include max {
        padding-right: 32px;
        height: 45px;
      }
    }
  }
  &__logo {
    padding: 26px 0 0 40px;
    @include max {
      padding: 17px 0 0 15px;
    }
    img {
      width: 98px;
      @include max {
        display: block;
        width: 50px;
      }
    }
  }
  &__nav {
    padding: 17px 0 0 50px;
    @include max(lg2) {
      padding-left: 20px;
    }
    @include max {
      position: absolute;
      right: 0;
      top: 45px;
      background-color: #fff;
      color: #000;
      width: 240px;
      height: calc(100vh - 45px);
      overflow-y: auto;
      padding: 0;
      transition: opacity .3s, transform .3s;
      &:not(.is-show) {
        pointer-events: none;
        transform: translateX(100%);
      }
      a {
        color: #000;
      }
    }
    ul {
      @include min {
        display: flex;
        flex-wrap: wrap;
      }
      @include max {
        padding: 27px 0 30px 40px;
      }
    }
    li {
      @include max {
      }
      a {
        display: block;
        padding: 10px;
        font-size: 12px;
        font-family: $f-of;
        @include min {
          &:hover {
            text-decoration: underline;
          }
        }
        @include max {
          font-size: 30px;
          padding: 8px 0;
        }
        &.-disable {
          pointer-events: none;
          opacity: 0.25;
        }
      }
    }
  }
  &__logo2 {
    margin-right: 22px;
    @include max {
      margin-right: 20px;
    }
    img {
      display: block;
      width: 232px;
      @include max {
        width: 116px;
      }
    }
  }
  &__btn {
    margin-right: 10px;
    @include max {
      margin: 0 0 50px;
    }
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 139px;
      height: 32px;
      background-color: #fff;
      color: #000;
      font-size: 12px;
      font-weight: bold;
      border-radius: 30px;
      @include max {
        font-size: 18px;
        background-color: #000;
        color: #fff;
        @include fsa;
        width: 200px;
        height: 38px;
        margin: 0 auto;
        font-weight: normal;
      }
    }
  }
  &__lang {
    position: relative;
  }
  &__langIcon {
    img {
      display: block;
      width: 32px;
    }
  }
  &__langSel {
    position: absolute;
    left: 50%;
    top: calc(100% + 12px);
    width: 120px;
    transform: translateX(-50%);
    display: flex;
    opacity: 0;
    pointer-events: none;
    transition: opacity .3s;
    @include max(lg) {
      top: calc(100% + 10px);
    }
    .p-hd__lang:hover & {
      opacity: 1;
      pointer-events: auto;
    }
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: -18px;
      width: 100%;
      height: 18px;
      @include max(lg) {
      }
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: -18px;
      border-style: solid;
      border-width: 9px;
      border-color: transparent transparent #fff transparent;
      @include max(lg) {
        top: -10px;
        border-width: 5px;
      }
    }
    span,
    a {
      width: 60px;
      height: 40px;
      padding: 0 !important;
      background-color: #fff;
      color: #000;
      font-size: 14px !important;
      display: flex !important;
      justify-content: center;
      align-items: center;
      position: relative !important;
      @include max(lg) {
        width: 44px;
        height: 28px;
        font-size: 10px !important;
      }
    }
    a {
      transition: background-color .3s;
      &:hover {
        background-color: #ccc;
      }
    }
    span {
      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 6px;
        background-color: #999;
        @include max(lg) {
          height: 3px;
        }
      }
    }
  }
  &__spLang {
    font-size: 18px;
    text-align: center;
    padding-bottom: 30px;
    span {
      text-decoration: underline;
    }
    a {
    }
  }
  &__menu {
    position: absolute;
    right: 0;
    top: 0;
    a {
      display: block;
      width: 45px;
      height: 45px;
      position: relative;
      span {
        display: block;
        position: absolute;
        left: 12px;
        top: 22px;
        width: 20px;
        height: 2px;
        background-color: #fff;
        transition: all .3s;
        &:nth-child(1) {
          top: 16px;
        }
        &:nth-child(3) {
          top: 28px;
        }
      }
      &.is-on span {
        top: 22px;
        &:nth-child(1) {
          transform: rotate(45deg);
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:nth-child(3) {
          transform: rotate(-45deg);
        }
      }
    }
  }
}

.p-format {
  a {
    color: #03c;
    text-decoration: none;
    @media screen and (min-width: 768px) {
      &:hover {
        color: #69f;
      }
    }
  }
  h2 {
    margin: 30px 0 20px;
    font-size: 24px;
    font-weight: bold;
  }
  h3 {
    margin: 30px 0 20px;
    font-size: 18px;
    font-weight: bold;
  }
  p, ul, ol, table {
    margin-bottom: 20px;
  }
  li {
    margin-bottom: 10px;
  }
  ul {
    list-style-type: disc;
    padding-left: 1.5em;
  }
  ol {
    list-style-type: decimal;
    padding-left: 1.5em;
  }
  hr {
    margin: 20px 0;
    border: none;
    border-top: 1px solid #999;
  }
  table {
    width: 100%;
  }
  th {
    width: 150px;
    padding: 10px 20px;
    border: 1px solid #999;
    background-color: #eee;
  }
  td {
    padding: 10px 20px;
    border: 1px solid #999;
  }
}

// フォント
$f-sans:
 acumin-pro,"游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic",
  "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;

$f-serif:
//  "Noto Serif JP",
//  "游明朝体", YuMincho,
  "Hiragino Mincho ProN", "游明朝", "Yu Mincho", serif;

$f-en:
  "Helvetica Neue", Helvetica, Arial;

$f-of: "Outfit";

$c-main: #CB99A6;

// メディアクエリ PCファースト
$breakpoints: (
  'xs': 350px,
  'sm': 576px,
  'md': 768px,
  'lg': 992px,
  'lg2': 1050px,
  'xl': 1200px
);

@mixin fsa {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

@mixin max($breakpoint: md) {
  $breakpoint: map_get($breakpoints, $breakpoint) - 1;
  @media screen and (max-width: $breakpoint) {
    @content;
  }
}

@mixin min($breakpoint: lg) {
  $breakpoint: map_get($breakpoints, $breakpoint);
  @media screen and (min-width: $breakpoint) {
    @content;
  }
}

// 要素置換
@mixin replace($original, $replacement) {
  @at-root #{selector-replace(&, $original, $replacement)} {
    @content
  }
}

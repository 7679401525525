.u-over {
  transition: opacity .3s;
  @media screen and (min-width: 768px) {
    &:hover {
      opacity: 0.7;
    }
  }
}

.u-imgCover {
  display: block;
  height: 0;
  padding-top: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.u-ytCoverWrap {
  @include max {
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
  }
}
.u-ytCover {
  position: relative;
  padding-top: 56.25%;
  @include max {
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
  }
  iframe {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  & > video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.u-ytCover2 {
  position: relative;
  padding-top: 56.25%;
  iframe {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  & > video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.u-txtLeft {text-align: left;}
.u-txtCenter {text-align: center;}
.u-txtRight {text-align: right;}

.u-inline {display: inline;}
.u-inlineBlock {display: inline-block;}
.u-block {display: block;}
.u-table {display: table;}
.u-tableCell {display: table-cell;}

@include max {
 .u-pc {display: none !important;}
}
@include max(sm) {
 .u-pcSm {display: none !important;}
}
@include max(lg) {
 .u-pcLg {display: none !important;}
}
@include max(xl) {
 .u-pcXl {display: none !important;}
}
@include min {
 .u-sp {display: none !important;}
}
@include min(sm) {
 .u-spSm {display: none !important;}
}
@include min(lg) {
 .u-spLg {display: none !important;}
}
@include min(xl) {
 .u-spXl {display: none !important;}
}

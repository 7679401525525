main {
  margin: 0 auto;
  padding-top: clamp(25px, 6.66666%, 50px);
  padding-left: clamp(15px, 4%, 30px);
  padding-right: clamp(15px, 4%, 30px);
  padding-bottom: clamp(100px, 26.66666%, 200px);
  max-width: 750px;
  background-color: #fff;
  @include max {
  }

}

h1 {
  width: clamp(30.234vw,30.234vw,413px);
  @include max {
    width: 85.7487vw;
  }
}

.main-copy {
  margin-top: clamp(9.1508vw,9.1508vw,130px);
  margin-left: clamp(32.9428vw,32.9428vw,450px);
  font-size: clamp(4.9048vw,4.9048vw,67px);
  line-height: 1.4;
  @include max {
    margin-top: 16.9082vw;
    margin-left: 0;
    font-size: 8.63526vw;
  }


}
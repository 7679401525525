.c-btn {
  display: flex;
  &.-center {
    justify-content: center;
  }
  a {
    width: 422px;
    height: 62px;
    background-color: #000;
    color: #fff;
    @include fsa;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    border-radius: 50px;
    @include max {
      width: 315px;
      height: 46px;
      font-size: 12px;
    }
  }
  &.-gra a {
    background: linear-gradient(120deg, #E5C7C4, #636485);
  }
  &.-sm a {
    width: 280px;
    height: 46px;
    @include max {
      width: 335px;
      height: 55px;
      font-size: 16px;
    }
  }
  &.-spSm a {
    @include max {
      width: 248px;
      height: 36px;
    }
  }
  &.-homeMovie a {
    font-size: 21px;
    @include max {
      font-size: 16px;
    }
    img.-icon {
      width: 23px;
      @include max {
        width: 18px;
      }
    }
    @include max {
    }
  }
  img.-icon {
    width: 17px;
    position: relative;
    top: 1px;
    @include max {
      width: 20px;
    }
  }
}